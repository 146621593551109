<template>
  <!-- Modal -->
  <div
    id="product_modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
          >
            選擇商品
          </h5>
        </div>
        <div class="modal-body">
          <div
            class="search_section"
          >
            <div class="form-group">
              <select
                id="system"
                v-model="search.system"
                name="系統"
                class="form-control"
                @change="get_main_category()"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  系統
                </option>
                <option
                  v-for="item in system"
                  :key="item.systemCode"
                  :value="item.systemCode"
                >
                  {{ item.systemName }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <select
                id="main_category"
                v-model="search.main_category"
                name="主分類"
                class="form-control"
                @change="get_sub_category()"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  主分類
                </option>
                <option
                  v-for="item in main_category_data"
                  :key="item.categoryCode"
                  :value="item.categoryCode"
                >
                  {{ item.categoryName }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <select
                id="sub_category"
                v-model="search.sub_category"
                name="次分類"
                class="form-control"
                @change="get_product_list()"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  次分類
                </option>
                <option
                  v-for="item in sub_category_data"
                  :key="item.categorySubCode"
                  :value="item.categorySubCode"
                >
                  {{ item.categorySubName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <div class="form-row">
              <div class="col-8">
                <select
                  id="system"
                  v-model="product_detail"
                  class="form-control mb-2"
                >
                  <option
                    v-for="(item, index) in filter_product_list"
                    :key="index"
                    :value="item"
                  >
                    {{ item.prodName + ' ' + item.standard }}
                  </option>
                </select>
                <div
                  v-if="product_detail.prodSerial"
                  class="form-group d-flex align-items-center"
                >
                  <label for="">商品編號</label>
                  <span>{{ product_detail.prodSerial }}</span>
                </div>
                <div
                  v-if="product_detail.price"
                  class="form-group d-flex align-items-center"
                >
                  <label for="">售價</label>
                  <span>{{ product_detail.price }}元</span>
                </div>
                <div
                  v-if="product_detail.optionShowFront"
                  class="form-group d-flex align-items-center"
                >
                  <label for="">前台顯示</label>
                  <span>{{ product_detail.optionShowFront ? '是' : '否' }}</span>
                </div>
              </div>
              <div class="col-4">
                <div v-if="product_detail.images">
                  <img
                    :src="product_detail.images.image430x430"
                    alt=""
                    class="img-fluid"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn_default"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="close_modal()"
          >
            關閉
          </button>
          <button
            type="button"
            class="btn btn_primary"
            @click.prevent="chose_product()"
          >
            確定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_PRODUCT_CATEGORY,
  API_GET_PRODUCT_LIST,
} from '@/plugins/api';

export default {
  data() {
    return {
      search: {
        /* 搜尋資料 */
        system: '',
        main_category: '',
        sub_category: '',
      },
      main_category_data: [],
      sub_category_data: [],
      product_list: [] /* 商品列表 */,
      product_detail: {},
    };
  },
  computed: {
    ...mapGetters('system', ['system']),
    filter_product_list: {
      get() {
        const vm = this;
        const data = vm.product_list || [];
        return data.filter((item) => item.optionShowFront);
      },
      set() {},
    },
  },
  methods: {
    async init_data() {
      const vm = this;
      vm.search = {
        system: '',
        main_category: '',
        sub_category: '',
      };
      vm.main_category_data = [];
      vm.sub_category_data = [];
      vm.product_list = [];
      vm.product_detail = {};
    },
    /* 取得主次分類列表 */
    async get_main_category() {
      const vm = this;
      vm.search.main_category = '';
      vm.search.sub_category = '';
      vm.main_category_data = [];
      vm.sub_category_data = [];
      vm.product_list = [];
      vm.product_detail = {};
      await vm.$store.dispatch('page_load', true);
      const { system } = vm.search;
      const response = await API_GET_PRODUCT_CATEGORY(system);
      const res = response.data;
      if (res.code === '0000') {
        vm.main_category_data = res.data;
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 篩選次分類列表 */
    async get_sub_category() {
      const vm = this;
      vm.main_category_data.forEach((item) => {
        if (item.categoryCode === vm.search.main_category) {
          vm.sub_category_data = item.items;
        }
      });
    },
    /* 取得商品列表 */
    async get_product_list() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('search');
      if (validateResult) {
        await vm.$store.dispatch('page_load', true);
        const response = await API_GET_PRODUCT_LIST(vm.search.sub_category);
        const res = response.data;
        if (res.code === '0000') {
          vm.product_list = res.data;
        }
        await vm.$store.dispatch('page_load', false);
      }
    },
    async chose_product() {
      const vm = this;
      const data = vm.product_detail;
      if (data.prodSerial === undefined) {
        vm.$swal({
          icon: 'warning',
          title: '尚未選擇商品',
          confirmButtonText: '關閉視窗',
        });
        return;
      }
      data.image430x430 = vm.product_detail.images.image430x430;
      vm.$store.commit('product/TEMP_PRODUCT', data);
      $('#product_modal').modal('toggle');
      await vm.init_data();
    },
    /* 關閉 modal */
    async close_modal() {
      const vm = this;
      $('#product_modal').modal('toggle');
      await vm.init_data();
    },
  },
};
</script>
