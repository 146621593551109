<template>
  <div class="page_content page_content_indexboard">
    <div class="content_header d-flex align-items-center">
      <span>版位管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div
          v-if="!edit_mode"
          class="search_section"
        >
          <div class="form-group">
            <select
              id="system"
              v-model="search.system"
              v-validate="'required'"
              name="系統"
              class="form-control search_brand"
              :class="{'is-invalid': errors.has('search.系統')}"
              data-vv-scope="search"
              @change="get_banners($event.target.value), get_blocks($event.target.value)"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                系統
              </option>
              <option
                v-for="item in system_data"
                :key="item.systemCode"
                :value="item.systemCode"
              >
                {{ item.systemName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label
              for="switch_btn_hide"
            >
              <input
                id="switch_btn_hide"
                v-model="option_hide"
                type="checkbox"
              >隱藏已失效
            </label>
          </div>
          <a
            v-if="search.system !== '' && nav_tab === 'banner'"
            href="#"
            class="btn btn_primary px-2"
            @click.prevent="init_validate(), initial_banner(true)"
          >
            <span>新增電視牆</span>
          </a>
          <a
            v-if="search.system !== '' && nav_tab !== 'banner'"
            href="#"
            class="btn btn_primary px-2"
            @click.prevent="init_validate(), initial_block(true)"
          >
            <span>新增區塊內容</span>
          </a>
        </div>
        <ul
          v-if="!edit_mode"
          class="nav nav-tabs"
        >
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tab === 'banner' }"
              @click.prevent="nav_tab = 'banner'"
            >電視牆</a>
          </li>
          <li
            v-for="item in blockType"
            :key="item.code"
            class="nav-item"
          >
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tab === item.code }"
              @click.prevent="nav_tab = item.code"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
        <div
          v-if="!edit_mode && nav_tab === 'banner'"
          class="table-responsive"
        >
          <table class="banner_table table table-borderless">
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  圖片
                </th>
                <th scope="col">
                  連結
                </th>
                <th scope="col">
                  目標頁
                </th>
                <th scope="col">
                  起始日
                </th>
                <th scope="col">
                  最後日
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  編輯
                </th>
              </tr>
            </thead>
            <draggable
              v-model="banners"
              tag="tbody"
              @change="uppdate_banner_order"
            >
              <tr
                v-for="(single_banner) in banners"
                :key="single_banner.serialNumber"
                :class="{ 'table_disabled': !single_banner.status }"
              >
                <td>
                  <div class="image_box">
                    <img
                      :src="single_banner.imageDesktop + '?' + `${Math.random()}`"
                      class="img-fluid"
                      alt=""
                    >
                  </div>
                </td>
                <td class="">
                  <a
                    :href="single_banner.linkPath"
                    class=""
                    target="_blank"
                  >{{ single_banner.linkPath.replace('https://www.gping.net', '.') }}</a>
                </td>
                <td v-if="single_banner.linkTarget === '_blank'">
                  新視窗
                </td>
                <td v-else>
                  本視窗
                </td>
                <td>
                  {{ single_banner.dtPublic }}
                </td>
                <td>
                  {{ single_banner.dtUnPublic }}
                </td>
                <td class="text-center">
                  <div class="d-flex justify-content-center">
                    <a
                      href="#"
                      class="btn btn_primary rounded-sm px-2 mx-1"
                      @click.prevent="get_banner(single_banner)"
                    >編輯</a>
                    <a
                      href="#"
                      class="banner_edit btn mx-1 text-danger"
                      @click.prevent="delete_banner(single_banner.serialNumber)"
                    >
                      <i class="icon-trash" />
                    </a>
                  </div>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <div
          v-if="!edit_mode && nav_tab === 'hotsale'"
          class="table-responsive"
        >
          <table class="table table-borderless">
            <thead class="thead-light">
              <tr>
                <th
                  scope="col"
                  width="150px"
                >
                  組合名稱
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  上架日期
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  最後日期
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  上架人員
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  編輯
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(single_block) in hotsale"
                :key="single_block.blockSerial"
              >
                <td>
                  組合名稱
                  <!-- {{ banner.name }} -->
                </td>
                <td class="text-center">
                  {{ single_block.dtPublic }}
                </td>
                <td class="text-center">
                  {{ single_block.dtUnPublic }}
                </td>
                <td class="">
                  {{ single_block.adminId }}
                </td>
                <td class="text-center">
                  <div class="d-flex justify-content-center">
                    <a
                      href="#"
                      class="btn btn_primary rounded-sm px-2 mx-1"
                      @click.prevent="get_block(single_block, 'hotsale')"
                    >編輯</a>
                    <a
                      href="#"
                      class="banner_edit btn mx-1 text-danger"
                      @click.prevent="delete_block(single_block.blockSerial)"
                    >
                      <i class="icon-trash" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="!edit_mode && nav_tab === 'newsale'"
          class="table-responsive"
        >
          <table class="table table-borderless">
            <thead class="thead-light">
              <tr>
                <th
                  scope="col"
                  width="150px"
                >
                  組合名稱
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  上架日期
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  最後日期
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  上架人員
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  編輯
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(single_block) in newsale"
                :key="single_block.blockSerial"
              >
                <td>
                  組合名稱
                  <!-- {{ banner.name }} -->
                </td>
                <td class="text-center">
                  {{ single_block.dtPublic }}
                </td>
                <td class="text-center">
                  {{ single_block.dtUnPublic }}
                </td>
                <td class="">
                  {{ single_block.adminId }}
                </td>
                <td class="text-center">
                  <div class="d-flex justify-content-center">
                    <a
                      href="#"
                      class="btn btn_primary rounded-sm px-2 mx-1"
                      @click.prevent="get_block(single_block, 'newsale')"
                    >編輯</a>
                    <a
                      href="#"
                      class="banner_edit btn mx-1 text-danger"
                      @click.prevent="delete_block(single_block.blockSerial)"
                    >
                      <i class="icon-trash" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="!edit_mode && nav_tab === 'headline'"
          class="table-responsive"
        >
          <table class="table table-borderless">
            <thead class="thead-light">
              <tr>
                <th
                  scope="col"
                  width="150px"
                >
                  組合名稱
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  上架日期
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  最後日期
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  上架人員
                </th>
                <th
                  scope="col"
                  width=""
                  class="text-center"
                >
                  編輯
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(single_block) in headline"
                :key="single_block.blockSerial"
              >
                <td>
                  組合名稱
                  <!-- {{ banner.name }} -->
                </td>
                <td class="text-center">
                  {{ single_block.dtPublic }}
                </td>
                <td class="text-center">
                  {{ single_block.dtUnPublic }}
                </td>
                <td class="">
                  {{ single_block.adminId }}
                </td>
                <td class="text-center">
                  <div class="d-flex justify-content-center">
                    <a
                      href="#"
                      class="btn btn_primary rounded-sm px-2 mx-1"
                      @click.prevent="get_block(single_block, 'headline')"
                    >編輯</a>
                    <a
                      href="#"
                      class="banner_edit btn mx-1 text-danger"
                      @click.prevent="delete_block(single_block.blockSerial)"
                    >
                      <i class="icon-trash" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="edit_mode && nav_tab === 'banner'"
          class="banner_edit_section"
        >
          <div class="section_header">
            <span v-if="edit_section_isnew">建立電視牆</span>
            <span v-else>編輯電視牆</span>
          </div>
          <overlay-scrollbars
            class="section_body"
            :options="{ scrollbars: { autoHide: 'scroll' } }"
          >
            <div
              v-if="!edit_section_isnew"
              class="form-group d-flex align-items-center"
            >
              <label>顯示狀態</label>
              <div class="form-check form-check-inline">
                <input
                  id="banner_status_true"
                  v-model="banner.status"
                  class="form-check-input"
                  type="radio"
                  name="banner_status"
                  :value="true"
                >
                <label
                  class="form-check-label"
                  for="banner_status_true"
                >是</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  id="banner_status_false"
                  v-model="banner.status"
                  class="form-check-input"
                  type="radio"
                  name="banner_status"
                  :value="false"
                >
                <label
                  class="form-check-label"
                  for="banner_status_false"
                >否</label>
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="banner_styleMode">模式</label>
              <select
                id="banner_styleMode"
                v-model="banner.styleMode"
                v-validate="'required'"
                name="模式"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('detail.模式')}"
                data-vv-scope="detail"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  模式
                </option>
                <option
                  v-for="item in option.styleMode"
                  :key="item.code"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="banner_linkPath">連結路徑</label>
              <input
                id="banner_linkPath"
                v-model="banner.linkPath"
                v-validate="'required'"
                type="text"
                name="連結路徑"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('detail.連結路徑')}"
                data-vv-scope="detail"
                placeholder="連結路徑"
              >
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="banner_dtPublic">上架日期</label>
              <input
                id="banner_dtPublic"
                v-model="banner.dtPublic"
                v-validate="'required'"
                type="date"
                name="上架日期"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('detail.上架日期')}"
                data-vv-scope="detail"
                placeholder="上架日期"
              >
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="banner_dtUnPublic">最後日期</label>
              <input
                id="banner_dtUnPublic"
                v-model="banner.dtUnPublic"
                v-validate="'required'"
                type="date"
                name="最後日期"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('detail.最後日期')}"
                data-vv-scope="detail"
                placeholder="最後日期"
              >
            </div>
            <div
              v-if="edit_mode"
              class="row justify-content-between"
            >
              <div class="col form-group form_input_image">
                <label>電腦圖片(1080x495.jpg)</label>
                <div class="input_custom">
                  <span>加入圖片</span>
                </div>
                <input
                  ref="files"
                  type="file"
                  data-name="imageDesktop"
                  class="form-control"
                  accept="image/jpeg"
                  @change="readURL($event,$event.target)"
                >
                <div class="display_image">
                  <img
                    id="imageDesktop"
                    :src="banner_images.imageDesktop"
                    class="img-fluid"
                  >
                </div>
              </div>
              <div class="col form-group form_input_image">
                <label>平板圖片(768x495.jpg)</label>
                <div class="input_custom">
                  <span>加入圖片</span>
                </div>
                <input
                  ref="files"
                  type="file"
                  data-name="imageTablet"
                  class="form-control"
                  accept="image/jpeg"
                  @change="readURL($event,$event.target)"
                >
                <div class="display_image">
                  <img
                    id="imageTablet"
                    :src="banner_images.imageTablet"
                    class="img-fluid"
                  >
                </div>
              </div>
              <div class="col form-group form_input_image">
                <label>手機圖片(375x667.jpg)</label>
                <div class="input_custom">
                  <span>加入圖片</span>
                </div>
                <input
                  ref="files"
                  type="file"
                  data-name="imageMobile"
                  class="form-control"
                  accept="image/jpeg"
                  @change="readURL($event,$event.target)"
                >
                <div class="display_image">
                  <img
                    id="imageMobile"
                    :src="banner_images.imageMobile"
                    class="img-fluid"
                  >
                </div>
              </div>
            </div>
          </overlay-scrollbars>
          <div class="section_footer">
            <div class="d-flex justify-content-end">
              <a
                href="#"
                class="btn btn_default rounded-0"
                @click.prevent="init_validate(), initial_banner(false)"
              >關閉</a>
              <a
                href="#"
                class="btn btn_primary rounded-0"
                @click.prevent="edit_banner(edit_section_isnew)"
              >{{ edit_section_isnew ? '建立' : '更新' }}</a>
            </div>
          </div>
        </div>
        <div
          v-if="edit_mode && nav_tab !== 'banner'"
          class="block_edit_section"
        >
          <div class="section_header">
            <span v-if="edit_section_isnew">建立區塊</span>
            <span v-else>編輯區塊</span>
          </div>
          <div class="section_body">
            <div class="form-group d-flex align-items-center">
              <label for="block_type">類型</label>
              <select
                id="block_type"
                v-model="block.type"
                v-validate="'required'"
                name="類型"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('block_detail.類型')}"
                data-vv-scope="block_detail"
              >
                <option
                  value
                  disabled
                  selected
                  hidden
                >
                  類型
                </option>
                <option
                  v-for="item in blockType"
                  :key="item.code"
                  :value="item.code"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="block_dtPublic">上架日期</label>
              <input
                id="block_dtPublic"
                v-model="block.dtPublic"
                v-validate="'required'"
                type="date"
                name="上架日期"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('block_detail.上架日期')}"
                data-vv-scope="block_detail"
                placeholder="上架日期"
              >
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="block_dtUnPublic">最後日期</label>
              <input
                id="block_dtUnPublic"
                v-model="block.dtUnPublic"
                v-validate="'required'"
                type="date"
                name="最後日期"
                class="form-control form_input"
                :class="{'is-invalid': errors.has('block_detail.最後日期')}"
                data-vv-scope="block_detail"
                placeholder="最後日期"
              >
            </div>
            <div class="form-group">
              <div class="d-flex align-items-center mb-2">
                <label>商品代碼</label>
                <a
                  href="#"
                  class="btn btn_outline_primary px-2"
                  @click.prevent="add_block_product()"
                >
                  新增
                </a>
              </div>
              <div class="row">
                <div
                  v-for="(item, index) in block.items"
                  :key="index"
                  class="col-3 mb-3"
                >
                  <div
                    class="block_card"
                  >
                    <div
                      class="block_delete"
                      @click="delete_block_product(index)"
                    >
                      <i class="icon-cross" />
                    </div>
                    <div
                      v-if="item.image430x430 !== ''"
                      class="block_image"
                    >
                      <img
                        :src="item.image430x430"
                        alt=""
                        class="img-fluid"
                      >
                    </div>
                    <a
                      href="#"
                      class="btn btn_outline_primary mb-2"
                      placeholder="選擇商品"
                      @click.prevent="toggle_modal(index)"
                    >選擇商品</a>
                    <span
                      v-if="item.prodName !== '' && item.standard !== ''"
                      :key="item.prodName"
                    >{{ item.prodName }}{{ item.standard }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section_footer">
            <div class="d-flex justify-content-end">
              <a
                href="#"
                class="btn btn_default rounded-0"
                @click.prevent="init_validate(), initial_block(false)"
              >關閉</a>
              <a
                href="#"
                class="btn btn_primary rounded-0"
                @click.prevent="edit_block(edit_section_isnew)"
              >{{ edit_section_isnew ? '建立' : '更新' }}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ProductModal />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_BANNER,
  API_ADD_BANNER,
  API_UPDATE_BANNER,
  API_DELETE_BANNER,
  API_GET_BLOCKS,
  API_ADD_BLOCK,
  API_UPDATE_BLOCK,
  API_DELETE_BLOCK,
  API_UPDATE_BANNER_ORDER,
} from '@/plugins/api';
import ProductModal from '@/components/ProductModal.vue';

export default {
  components: {
    draggable,
    ProductModal,
  },
  data() {
    return {
      now_page: 'indexboard',
      search: {
        /* 搜尋資料 */
        system: '',
        statusHide: '1', /* 隱藏失效項目狀態 */
      },
      nav_tab: 'banner',
      edit_mode: false, /* 編輯模式 */
      edit_section_isnew: false, /* 編輯區塊是否為新 */
      banners: [] /* 電視牆列表 */,
      banner: {
        /* 電視牆細節 */
        styleMode: '',
        contentDef1: '',
        contentDef2: '',
        contentDef3: '',
        contentDef4: '',
        linkPath: '',
        linkTarget: '_blank',
        imageDesktop: '',
        imageMobile: '',
        imageTablet: '',
        optionHide: false, /* 隱藏失效項目 */
      },
      headline: [] /* 頭條資料 */,
      hotsale: [] /* 熱銷排行 */,
      newsale: [] /* 新品資料 */,
      block: {
        /* 區塊細節 */
        type: '',
        dtPublic: '',
        dtUnPublic: '',
        items: [],
      },
      dragging: false, /* 拖曳用 */
      temp_product_index: 0,
      option_hide: true,
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option']),
    ...mapGetters('product', ['temp_product']),
    system_data: {
      get() {
        const vm = this;
        const data = vm.system.slice();
        return data;
      },
    },
    blockType: {
      get() {
        const vm = this;
        const data = vm.option.blockType ? vm.option.blockType.slice() : [];
        data.forEach((item, index) => {
          if (item.code === 'headline') {
            data.push(item);
            data.splice(index, 1);
          }
        });
        return data;
      },
    },
    banner_images() {
      const vm = this;
      const data = {
        imageDesktop: `${process.env.VUE_APP_API_PATH}/images/site/banner/Desktop.jpg`,
        imageTablet: `${process.env.VUE_APP_API_PATH}/images/site/banner/Tablet.jpg`,
        imageMobile: `${process.env.VUE_APP_API_PATH}/images/site/banner/Mobile.jpg`,
      };
      if (vm.banner.imageDesktop !== '') {
        data.imageDesktop = vm.banner.imageDesktop;
      }
      if (vm.banner.imageTablet !== '') {
        data.imageTablet = vm.banner.imageTablet;
      }
      if (vm.banner.imageMobile !== '') {
        data.imageMobile = vm.banner.imageMobile;
      }
      return data;
    },
  },
  watch: {
    temp_product: {
      async handler(newValue, oldValue) {
        const vm = this;
        vm.$set(vm.block.items, vm.temp_product_index, newValue);
      },
      deep: true,
      immediate: true,
    },
    option_hide: {
      async handler(newValue, oldValue) {
        const vm = this;
        vm.banner.optionHide = newValue;
        vm.search.statusHide = newValue ? '1' : '0';
        if (vm.search.system !== '') {
          vm.get_banners(vm.search.system);
        }
      },
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_system_option();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system', 'get_system_option']),
    /* 加入區塊商品 */
    async add_block_product() {
      const vm = this;
      const data = {
        prodSerial: '',
      };
      vm.block.items.push(data);
    },
    async toggle_modal(index) {
      const vm = this;
      vm.temp_product_index = index;
      await vm.$store.dispatch('product/toggle_modal');
    },
    /* 刪除區塊商品 */
    async delete_block_product(index) {
      const vm = this;
      vm.block.items.splice(index, 1);
    },
    /* 取得 banner 資料 */
    async get_banners(value) {
      const vm = this;
      const systemCode = value;
      await vm.$store.dispatch('page_load', true);
      if (systemCode !== '') {
        const response = await API_GET_BANNER(systemCode, vm.search.statusHide);
        const res = response.data;
        // console.log('取得 banner 成功', res);
        if (res.code === '0000') {
          vm.banners = res.data;
          vm.nav_tab = 'banner';
        }
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 初始化電視牆、區塊細節型別 */
    async initial_banner(isNew) {
      const vm = this;
      if (isNew) {
        vm.banner = {
          styleMode: '',
          optionHide: vm.option_hide,
          contentDef1: '',
          contentDef2: '',
          contentDef3: '',
          contentDef4: '',
          linkPath: '',
          linkTarget: '_blank',
          imageDesktop: '',
          imageMobile: '',
          imageTablet: '',
        };
      }
      vm.edit_mode = isNew;
      vm.edit_section_isnew = isNew;
    },
    async initial_block(isNew) {
      const vm = this;
      if (isNew) {
        vm.block = {
          type: '',
          dtPublic: '',
          dtUnPublic: '',
          items: [],
        };
      }
      vm.edit_mode = isNew;
      vm.edit_section_isnew = isNew;
    },
    /* 初始化驗證 */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('search');
        vm.$validator.errors.clear('detail');
        vm.$validator.errors.clear('block_detail');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    /* */
    async get_banner(banner) {
      const vm = this;
      vm.banner = banner;
      vm.$set(vm.banner, 'imageDesktop', banner.imageDesktop);
      vm.$set(vm.banner, 'imageMobile', banner.imageMobile);
      vm.$set(vm.banner, 'imageTablet', banner.imageTablet);
      vm.edit_mode = true;
      vm.edit_section_isnew = false;
      $('html, body').animate({
        scrollTop: $('#app').height(),
      }, 300);
    },
    /* 讀取圖片 */
    async readURL(e, input) {
      const vm = this;
      const targetId = e.target.dataset.name;
      if (input.files.length > 0) {
        input.files.forEach((item) => {
          const reader = new FileReader();
          reader.onload = function onload(readerE) {
            const img = $(e.target).siblings('img');
            $(img).attr('src', readerE.target.result);
            vm.$set(vm.banner, `${e.target.dataset.name}`, readerE.target.result);
          };
          reader.readAsDataURL(item);
        });
      }
    },
    /* 檢查 banner */
    async edit_banner(isNew) {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        if (isNew) {
          await vm.create_banner();
        } else {
          await vm.update_banner();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 新增 banner */
    async create_banner() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = new FormData();
      data.append('systemCode', vm.search.system);
      data.append('styleMode', vm.banner.styleMode);
      data.append('optionHide', vm.option_hide);
      data.append('contentDef1', '');
      data.append('contentDef2', '');
      data.append('contentDef3', '');
      data.append('contentDef4', '');
      data.append('linkPath', vm.banner.linkPath);
      data.append('linkTarget', vm.banner.linkTarget);
      data.append('dtPublic', vm.banner.dtPublic);
      data.append('dtUnPublic', vm.banner.dtUnPublic);
      if (
        vm.banner.imageMobile === ''
        || vm.banner.imageTablet === ''
        || vm.banner.imageDesktop === ''
      ) {
        vm.$swal({
          icon: 'error',
          title: '請選擇圖片!',
          confirmButtonText: '關閉視窗',
        });
        return;
      }
      const imageData = Array.from(
        document.querySelectorAll('.form_input_image input'),
      );
      imageData.forEach((image) => {
        if (image.files.length > 0) {
          data.append(`${image.dataset.name}`, image.files[0]);
        }
      });
      const response = await API_ADD_BANNER(data);
      const res = response.data;
      // console.log('新增電視牆 成功', response);
      if (res.code === '0000') {
        await vm.init_validate();
        await vm.initial_banner(false);
        await vm.get_banners(vm.search.system);
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 更新 banner */
    async update_banner() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = new FormData();
      data.append('serialNumber', vm.banner.serialNumber);
      data.append('status', vm.banner.status);
      data.append('styleMode', vm.banner.styleMode);
      data.append('optionHide', vm.option_hide);
      data.append('contentDef1', '');
      data.append('contentDef2', '');
      data.append('contentDef3', '');
      data.append('contentDef4', '');
      data.append('linkPath', vm.banner.linkPath);
      data.append('linkTarget', vm.banner.linkTarget);
      data.append('dtPublic', vm.banner.dtPublic);
      data.append('dtUnPublic', vm.banner.dtUnPublic);
      data.append('imageDesktop', '');
      data.append('imageTablet', '');
      data.append('imageMobile', '');
      const imageData = Array.from(
        document.querySelectorAll('.form_input_image input'),
      );
      imageData.forEach((image) => {
        if (image.files.length > 0) {
          data.set(`${image.dataset.name}`, image.files[0]);
        }
      });
      const response = await API_UPDATE_BANNER(data);
      const res = response.data;
      // console.log('更新電視牆 成功', response);
      if (res.code === '0000') {
        await vm.init_validate();
        await vm.initial_banner(false);
        await vm.get_banners(vm.search.system);
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 刪除 banner */
    async delete_banner(serialNumber) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_DELETE_BANNER(serialNumber, vm.search.statusHide);
      const res = response.data;
      // console.log('刪除電視牆 成功', response);
      if (res.code === '0000') {
        await vm.get_banners(vm.search.system);
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得首頁區塊 */
    async get_blocks(value) {
      const vm = this;
      const systemCode = value;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_BLOCKS(systemCode, vm.search.statusHide);
      const res = response.data;
      // console.log('取得區塊 成功', response.data);
      if (res.code === '0000') {
        vm.headline = res.data.headline;
        vm.hotsale = res.data.hotsale;
        vm.newsale = res.data.newsale;
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得 block 細節 */
    async get_block(block, type) {
      const vm = this;
      vm.block = block;
      vm.block.type = type;
      vm.edit_mode = true;
      vm.edit_section_isnew = false;
    },
    /* 檢查 block */
    async edit_block(isNew) {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('block_detail');
      if (validateResult) {
        if (isNew) {
          await vm.create_block();
        } else {
          await vm.update_block();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 新增 block */
    async create_block() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = {
        systemCode: vm.search.system,
        optionHide: vm.option_hide,
        type: vm.block.type,
        dtPublic: vm.block.dtPublic,
        dtUnPublic: vm.block.dtUnPublic,
        prodSerials: [],
      };
      vm.block.items.forEach((item) => {
        data.prodSerials.push(item.prodSerial);
      });
      const response = await API_ADD_BLOCK(data);
      const res = response.data;
      // console.log('新增 block 成功', response);
      if (res.code === '0000') {
        await vm.init_validate();
        await vm.initial_block(false);
        await vm.get_blocks(vm.search.system);
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 更新 block */
    async update_block() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = {
        blockSerial: vm.block.blockSerial,
        optionHide: vm.option_hide,
        type: vm.block.type,
        dtPublic: vm.block.dtPublic,
        dtUnPublic: vm.block.dtUnPublic,
        prodSerials: [],
      };
      vm.block.items.forEach((item) => {
        data.prodSerials.push(item.prodSerial);
      });
      const response = await API_UPDATE_BLOCK(data);
      const res = response.data;
      // console.log('更新 block 成功', response);
      if (res.code === '0000') {
        await vm.init_validate();
        await vm.initial_block(false);
        await vm.get_blocks(vm.search.system);
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 刪除 block */
    async delete_block(blockSerial) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_DELETE_BLOCK(blockSerial, vm.search.statusHide);
      const res = response.data;
      // console.log('刪除區塊成功', response);
      if (res.code === '0000') {
        await vm.init_validate();
        await vm.initial_block(false);
        await vm.get_blocks(vm.search.system);
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 更新電視牆排序 */
    async uppdate_banner_order({ moved }) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = {
        systemCode: vm.search.system,
        optionHide: vm.option_hide,
        serialNumber: moved.element.serialNumber,
        sort: moved.newIndex + 1,
      };
      const response = await API_UPDATE_BANNER_ORDER(data);
      const res = response.data;
      if (res.code === '0000') {
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          timer: 1000,
          confirmButtonText: '關閉視窗',
        });
        await vm.get_banners(vm.search.system);
      }
      await vm.$store.dispatch('page_load', false);
    },
  },
};
</script>
